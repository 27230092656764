<template>
  <div class="nav_box">
    <el-alert style="position: fixed; top: 0; left: 0; z-index: 9" v-if="alert_show" :title="alert_text" type="error"
      effect="dark" show-icon @close="alert_close_way">
    </el-alert>
    <div class="navigation_bar" :style="'color:' + color">
      <div class="absolute">
        <div class="plate">
          <el-row type="flex" justify="end" class="login_view">
            <el-col :span="5" class="login_btn">
              <!-- <div>
                <span style="font-size: 18px"
                      class="iconfont icon-wode1"></span>
              </div> -->
              <div @click="pathWay('/login')" v-if="!userInfo.email">
                SIGN-IN
              </div>
              <div v-else>
                <el-popover placement="top">
                  <p class="popoverText" @click="pathWay('/orderView')">Review Order</p>
                  <p class="popoverText" @click="logoutWay">logout<i class="el-icon-switch-button"></i></p>
                  <div slot="reference">{{ userInfo.email }}</div>
                </el-popover>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="navigation_view">
            <el-col :span="10">
              <div @click="pathWay('/')" class="logo"
                :class="{ baizilogo: logo == 'baizi', heizilogo: logo == 'heizi' }">
              </div>
            </el-col>
            <el-col>
              <div class="navigation_li" v-if="navigation_li">
                <div class="li" @click="nav_way(0)">
                  Airport Transfers
                  <i class="Underline" :class="{ show: navValue == 0 }"></i>
                </div>
                <div class="li" @click="nav_way(1)">
                  City Rides
                  <i class="Underline" :class="{ show: navValue == 1 }"></i>
                </div>
                <div class="li" @click="nav_way(2)">
                  Hourly Service
                  <i class="Underline" :class="{ show: navValue == 2 }"></i>
                </div>
                <div class="li" @click="nav_way(3)">
                  <el-popover placement="top" width="350" v-model="visible">
                    <div style="display: flex;justify-content: space-between;font-size: 16px;">
                      <div>
                        Contact number:
                      </div>
                      <div>
                        +1 973 826 7620
                      </div>
                    </div>

                    <div style="display: flex;justify-content: space-between;font-size: 16px;">
                      <div>
                        WhatsApp:
                      </div>
                      <div>
                        +1 914 497 6774
                      </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;font-size: 16px;">
                      <div>
                        Email address:
                      </div>
                      <div>
                        kelimoservice@gmail.com
                      </div>
                    </div>

                    <div slot="reference">
                      Contact US
                      <i class="Underline" :class="{ show: navValue == 3 }"></i>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="navigation_li" v-else>
                <div class="li">{{ leftTxt }}</div>
              </div>
              <div class="more_btn">
                <span v-if="!menu_show" @click="more_show_way" style="font-size: 28px; cursor: pointer"
                  class="iconfont icon-hanbaocaidan"></span>
                <span v-else @click="more_close_way" style="font-size: 28px; cursor: pointer"
                  class="iconfont icon-guanbicaidan"></span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="menu_view" :class="{ menu_view_show: menu_show }">
          <div class="plate">
            <div v-if="navigation_li" class="li" @click="nav_way(1)">
              Airport Transfers
              <i class="Underline" :class="{ show: navValue == 0 }"></i>
            </div>
            <div v-if="navigation_li"></div>
            <div v-if="navigation_li" class="li" @click="nav_way(1)">
              City Rides
              <i class="Underline" :class="{ show: navValue == 1 }"></i>
            </div>
            <div v-if="navigation_li"></div>
            <div v-if="navigation_li" class="li" @click="nav_way(2)">
              Hourly Service
              <i class="Underline" :class="{ show: navValue == 2 }"></i>
            </div>
            <div v-if="navigation_li"></div>
            <div v-if="navigation_li" class="li" @click="nav_way(3)">
              <el-popover placement="top" width="350" v-model="visible">
                <div style="display: flex;justify-content: space-between;font-size: 16px;">
                  <div>
                    Contact number:
                  </div>
                  <div>
                    +1 973 826 7620
                  </div>
                </div>

                <div style="display: flex;justify-content: space-between;font-size: 16px;">
                  <div>
                    WhatsApp:
                  </div>
                  <div>
                    +1 914 497 6774
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;font-size: 16px;">
                  <div>
                    Email address:
                  </div>
                  <div>
                    kelimoservice@gmail.com
                  </div>
                </div>

                <div slot="reference">
                  Contact US
                  <i class="Underline"></i>
                </div>
              </el-popover>

            </div>
            <div v-if="navigation_li"></div>
            <div class="line"></div>
            <div class="li" v-if="!userInfo.email" @click="pathWay('/login')">
              <span style="font-size: 24px" class="iconfont icon-wode1"></span>
              SIGN-IN
            </div>
            <div class="li" v-else @click="pathWay('/orderView')">

              <el-popover placement="top">
                <p class="popoverText" @click="pathWay('/orderView')">Review Order</p>
                <p class="popoverText" @click="logoutWay">logout<i class="el-icon-switch-button"></i></p>
                <div slot="reference"> <span style="font-size: 24px" class="iconfont icon-wode1"></span>{{
                  userInfo.email
                }}</div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="division_line"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "navView",
  props: {
    logo: {
      type: String,
      default: 'baizi',
    },
    color: {
      type: String,
      default: "#fff",
    },
    navigation_li: {
      type: Boolean,
      default: true,
    },
    leftTxt: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // 控制alert是否显示
      alert_show: false,
      // 提示文字
      alert_text: "No route found, sorry",
      // 是否显示菜单框
      menu_show: false,
      // 当前导航栏
      navValue: this.$store.state.navValue,
      // 用户信息
      userInfo: {},
    };
  },
  mounted() {
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }
  },
  methods: {
    // 打开alert
    alert_show_way(tips) {
      this.alert_text = tips;
      this.alert_show = true;
    },
    // 关闭alert
    alert_close_way() {
      this.alert_show = false;
    },
    //  打开 菜单框
    more_show_way() {
      this.menu_show = true;
    },
    // 关闭菜单框
    more_close_way() {
      this.menu_show = false;
    },
    // 切换导航栏
    nav_way(row) {
      if (row == 3) {
        return
      }
      this.navValue = row;
      this.$store.commit("navValueWay", this.navValue);
    },
    pathWay(path) {
      this.$router.push(path);
    },
    logoutWay() {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('userInfo')
      this.$router.push('/');
    }
  },
};
</script>
<style lang="scss" scoped>
.popoverText {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  line-height: 30px;
  user-select: none;
  text-decoration: underline;
}

.nav_box {
  width: 100vw;
}

.el-alert {
  padding: 20px 16px;
}

.navigation_bar {
  position: relative;
  color: #ffffff;

  .login_view {
    padding: 15px 0;

    .login_btn {
      display: flex;
      font-weight: 700;
      cursor: pointer;
      justify-content: flex-end;
      font-size: 12px;
    }
  }

  .absolute {
    position: absolute;
    z-index: 999;
    width: 100vw;
  }

  .navigation_view {
    padding: 15px 0;

    .logo {
      width: 160px;
      height: 45px;
      // background-image: url("@/assets/logo/baiselogo.png");
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .baizilogo {
      background-image: url("@/assets/logo/baiselogo.png") !important;
    }

    .heizilogo {
      background-image: url("@/assets/logo/heizilogo.png") !important;
    }

    .navigation_li {
      display: flex;
      line-height: 45px;
      font-weight: 700;
      font-size: 20px;
      justify-content: flex-end;

      .li {
        position: relative;
        cursor: pointer;
        margin: 0 10px;

        .Underline {
          position: absolute;
          left: 50%;
          bottom: 0px;
          transform: translateX(-50%);
          width: 0%;
          height: 3px;
          background-color: #fff;
          border-radius: 2px;
          transition: 0.3s;
        }

        .show {
          width: 100% !important;
        }
      }

      .li:hover {
        .Underline {
          width: 100%;
        }
      }

      .li:last-child {
        margin-right: 0;
      }
    }

    .more_btn {
      display: none;
      justify-content: flex-end;
      line-height: 45px;
    }
  }

  .menu_view {
    display: none;
  }

  .division_line {
    width: 100vw;
    height: 2px;
    background-color: #cccccc30;
  }
}

@media screen and (max-width: 992px) {
  .navigation_bar {
    .login_view {
      display: none;
    }

    .absolute {
      position: relative;

      .plate {
        width: 95% !important;
      }
    }

    .navigation_view {
      .logo {
        width: 160px;
        background-size: contain;
        background-repeat: no-repeat;
        height: 35px;
      }

      .baizilogo {
        background-image: url("@/assets/logo/heizilogo.png") !important;
      }

      .heizilogo {
        background-image: url("@/assets/logo/heizilogo.png") !important;
      }

      .navigation_li {
        display: none;
      }

      .more_btn {
        display: flex;
        color: #000;
        line-height: 35px;
        padding-right: 20px;
      }
    }

    .menu_view_show {
      padding: 10px 0 10px;
      height: auto !important;
    }

    .menu_view {
      display: block;
      background-color: #fff;
      transition: 0.3s;
      height: 0px;
      overflow: hidden;

      .li {
        display: inline-block;
        position: relative;
        height: 40px;
        color: #000;
        font-weight: 700;
        cursor: pointer;
        font-size: 20px;
        line-height: 40px;

        .Underline {
          position: absolute;
          left: 0%;
          bottom: 1px;
          width: 0%;
          height: 3px;
          background-color: #000;
          border-radius: 2px;
          transition: 0.3s;
        }

        .show {
          width: 100% !important;
        }
      }

      .li:hover {
        .Underline {
          width: 100%;
        }
      }

      .line {
        height: 2px;
        background-color: #cccccc30;
        margin: 5px 0;
      }
    }

    .division_line {
      display: none;
    }
  }
}
</style>
<style>
.el-alert__closebtn {
  top: 50% !important;
  transform: translateY(-50%);
}
</style>
<template>
  <div id="app">
    <router-view />
    <!-- <FloatingHomeButton /> -->
  </div>
</template>
<script>
((g) => {
  var h,
    a,
    k,
    p = "The Google Maps JavaScript API",
    c = "google",
    l = "importLibrary",
    q = "__ib__",
    m = document,
    b = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f, n) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l]
    ? console.warn(p + " only loads once. Ignoring:", g)
    : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
})({
  key: "AIzaSyA1rGaAjpiQqjvo3-ZuKAqwwLkVBMwLDjI",
  v: "weekly",
  language: "en",
});
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}

* {
  padding: 0;
  margin: 0;
}

.plate {
  width: 80%;
  margin: 0 auto;
}

body {
  width: 99vw;
  font-family: EuclidFlexReg, sans-serif;
  -webkit-font-smoothing: antialiased;
}

input::placeholder {
  font-family: EuclidFlexReg, sans-serif;
  -webkit-font-smoothing: antialiased;
}

img {
  width: 100%;
  height: 100%;
}

/* xs*/
@media screen and (max-width: 768px) {
  .plate {
    width: 85%;
    margin: 0 auto;
  }
}

/*  sm*/
@media screen and (max-width: 992px) {
  .plate {
    width: 85%;
    margin: 0 auto;
  }
}

/*  md */
@media screen and (max-width: 1200px) {
  .plate {
    width: 85%;
    margin: 0 auto;
  }
}

.agreement_box {
  width: 600px !important;
  border-radius: 10px !important;
  overflow: hidden;

  .dialog_title {
    font-weight: 400;
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    i {
      cursor: pointer;
    }
  }

  .el-dialog__header {
    border-bottom: 1px solid #ccc;
  }

  .el-dialog__body {
    padding: 20px !important;
    height: 60vh;
  }

  .dialog-footer {
    .btn {
      display: inline-block;
      font-weight: 400;
      color: #212529;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      background-color: #6ebc92;
      border-color: #6ebc92;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import FloatingHomeButton from './components/FloatingHomeButton.vue';
Vue.component('FloatingHomeButton',FloatingHomeButton)
Vue.use(ElementUI, { locale })
import './assets/icon/iconfont.css'
import './assets/ttf/font.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
// Vue.use(FlatPickr);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navValue: 0,
    orderEntity: {},
    carCityList: [],
    // 选择的车型数据
    vehicleModelData: {},
    // 
  },
  getters: {
  },
  mutations: {
    navValueWay(state, news) {
      state.navValue = news
    },
    orderEntityWay(state, news) {
      state.orderEntity = news
    },
    carCityListWay(state, news) {
      state.carCityList = news
    },
    vehicleModelDataWay(state, news) {
      state.vehicleModelData = news
    },
  },
  actions: {
  },
  modules: {
  }
})

// 引入
import request from "@/utils/request";
//  鉴定折扣券是否有效
export const getSearchCoupon = (query) => {
  return request({
    url: "/api/searchCoupon",
    method: "get",
    params: query,
  });
};
// 搜索车类型
export const getSearchCarList = (data) => {
  return request({
    url: "/api/searchCarList",
    method: "post",
    data: data,
  });
};
// 创建订单
export const createOrder = (data) => {
  return request({
    url: "/api/createOrder",
    method: "post",
    data: data,
  });
};
// 发起预支付
export const advancePayment = (query) => {
  return request({
    url: "/api/order/pay",
    method: "get",
    params: query,
  });
};
// iphone 登录
export const iphoneLogin = (data) => {
  return request({
    url: "/api/iphoneLogin",
    method: "post",
    data: data,
  });
};
// goole 登录
export const googleLogin = (data) => {
  return request({
    url: "/api/googleLogin",
    method: "post",
    data: data,
  });
};
// 账号密码登录
export const accountLogin = (query) => {
  return request({
    url: "/app/accountLogin",
    method: "get",
    params: query,
  });
};
// 查询订单详情
export const getOrderById = (query) => {
  return request({
    url: "/api/getOrderById",
    method: "get",
    params: query,
  });
};
// 获取用户信息
export const getUserInfo = (query) => {
  return request({
    url: "/api/getUserInfo",
    method: "get",
    params: query,
  });
};
// 获取订单列表
export const listUserOrders = (query) => {
  return request({
    url: "/api/listUserOrders",
    method: "get",
    params: query,
  });
};
// 修改用户信息
export const updateUser = (query) => {
  return request({
    url: "/api/updateUser",
    method: "post",
    data: query,
  });
};
// 注册
export const accountRegister = (query) => {
  return request({
    url: "/app/accountRegister",
    method: "get",
    params: query,
  });
};
// 注册
export const listCars = (query) => {
  return request({
    url: "api/listCars",
    method: "get",
    params: query,
  });
};
// 获取评论
export const listAppRaise = (query) => {
  return request({
    url: "api/listAppRaise",
    method: "get",
    params: query,
  });
};
// 添加返程
export const addBackTrackIng = (query) => {
  return request({
    url: "/api/addBackTrackIng",
    method: "get",
    params: query,
  });
};

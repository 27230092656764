import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/orderView',
    name: 'orderView',
    component: () => import('../views/orderView.vue'),
    children: [
      {
        path: '/',
        name: 'order',
        component: () => import('../views/order.vue'),
      },
      {
        path: '/edituser',
        name: 'edituser',
        component: () => import('../views/edituser.vue'),
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    children: [{
      path: '/',
      name: 'selectVehicle',
      component: () => import('../views/selectVehicle.vue'),
    },
    {
      path: 'extrasNotes',
      name: 'extrasNotes',
      component: () => import('../views/extrasNotes.vue'),
    },
    {
      path: 'leadPassenger',
      name: 'leadPassenger',
      component: () => import('../views/leadPassenger.vue'),
    },
    {
      path: 'payment',
      name: 'payment',
      component: () => import('../views/payment.vue'),
    }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    // 始终回到顶部
    return { x: 0, y: 0 }
  }
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router

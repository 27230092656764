<template>
    <div class="floating-button" @click="goHome">
        <i class="home-icon">
            <svg t="1723014976197" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="7860" width="20" height="20">
                <path
                    d="M462.449778 78.023111a77.596444 77.596444 0 0 1 100.010666-3.470222l4.124445 3.498667 415.658667 399.473777a38.798222 38.798222 0 0 1 2.844444 54.812445c-13.482667 14.990222-35.84 16.952889-52.821333 4.295111l-3.328-2.730667v411.875556a77.568 77.568 0 0 1-72.135111 77.482666l-5.603556 0.199112h-231.480889v-233.671112a54.613333 54.613333 0 0 0-49.92-54.528l-4.835555-0.199111h-106.382223a54.755556 54.755556 0 0 0-54.556444 49.92l-0.199111 4.807111v233.671112h-226.133334a77.738667 77.738667 0 0 1-77.511111-72.106667l-0.199111-5.603556V528.810667l-6.684444 6.343111a38.883556 38.883556 0 0 1-54.613334-3.072 38.769778 38.769778 0 0 1-0.369777-51.569778l2.929777-2.986667L462.421333 78.023111z m51.996444 68.266667L185.315556 458.467556v479.658666h133.176888V789.902222a139.946667 139.946667 0 0 1 35.896889-93.781333l5.12-5.404445a140.060444 140.060444 0 0 1 91.648-40.789333l7.395556-0.199111h106.382222a140.088889 140.088889 0 0 1 139.946667 132.664889l0.170666 7.395555-0.028444 148.337778h138.581333V462.620444L514.446222 146.289778z"
                    fill="#ffffff" p-id="7861"></path>
            </svg>
        </i>
    </div>
</template>

<script>
export default {
    name: "FloatingHomeButton",
    methods: {
        goHome() {
            // 在这里定义返回主页的逻辑，例如：
            this.$router.push({ name: 'home' });
        }
    }
};
</script>

<style scoped>
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #494949;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;

    i {
        font-style: normal;
    }
}

.floating-button:active {
    background-color: #e64a19;
    transform: scale(1.1);
}

.home-icon {
    font-size: 10px;
}
</style>
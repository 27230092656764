<template>
  <div class="home" @click="home_way">
    <navView ref="nav_ref"></navView>
    <div class="head_view">
      <div class="head_background" :class="{
        head_background1: navValue == 1,
        head_background2: navValue == 0,
        head_background3: navValue == 2,
      }"></div>
      <div class="head_background_two"></div>
      <div class="head_upper_strata">
        <div class="plate" style="position: relative">
          <div class="operation_view">
            <div class="left_view">
              U.S. Airport Transfers<br />
              <span style="font-size: 34px;line-height: 45px;display: inline-block;">
                Unlimited Waiting & Free Changing Service<br>
                (Terms and Conditions Applied)
              </span>
            </div>
            <div class="query_view">
              <div class="choice_view">
                <div class="each" :class="{ select_each: pattern == 'Ride' }" @click="pattern_way('Ride')">
                  Ride
                </div>
                <div class="each" :class="{ select_each: pattern == 'Bythehour' }" @click="pattern_way('Bythehour')">
                  <span class="iconfont icon-shijian"></span> By the Hour
                </div>
              </div>
              <div class="condition_view">
                <div class="each_input_box">
                  <div class="name">From</div>
                  <div class="input_box" @click.stop>
                    <span class="iconfont icon-weizhi"></span>
                    <input @focus="blur_way" @input="blur_way" v-model="fromValue" type="textarea"
                      placeholder="Address,airport,hotel,..." />
                    <div class="close_input" v-if="fromValue" @click.stop="close_input('fromValue')">
                      <span class="el-icon-error"></span>
                    </div>
                  </div>
                  <div @click.stop class="selection_box" v-loading="selection_from_loading"
                    element-loading-text="loading..." element-loading-spinner="el-icon-loading" id="fromValue">
                    <el-scrollbar style="height: 300px">
                      <ul id="results" class="results">
                        <li @click="formisPCWay" @touchstart="handleTouchStart" @touchend="handleTouchEndfrom"
                          v-for="item, index in fromValueList" :key="index">
                          {{ item }}
                        </li>
                      </ul>
                    </el-scrollbar>
                  </div>
                </div>

                <div class="each_input_box" v-if="pattern != 'Bythehour'">
                  <div class="name">To</div>
                  <div class="input_box" @click.stop>
                    <span class="iconfont icon-weizhi"></span>
                    <input type="text" @focus="to_blur_way" @input="to_blur_way" v-model="toValue"
                      placeholder="Address,airport,hotel,..." />
                    <div class="close_input" v-if="toValue" @click.stop="close_input('toValue')">
                      <span class="el-icon-error"></span>
                    </div>
                  </div>
                  <div @click.stop class="selection_box" v-loading="selection_to_loading"
                    element-loading-text="loading..." element-loading-spinner="el-icon-loading" id="toValue">
                    <el-scrollbar style="height: 300px">
                      <ul id="resultss" class="results">
                        <li @click="toisPCWay" @touchstart="handleTouchStart" @touchend="handleTouchEndto"
                          v-for="item, index in toValueList" :key="index">
                          {{ item }}
                        </li>
                      </ul>
                    </el-scrollbar>
                  </div>
                </div>
                <div class="time_box">
                  <div class="each_input_box">
                    <div class="name">
                      <span class="iconfont icon-riqi"></span> <span style="font-weight: 400;">Pickup Date</span>
                    </div>
                    <div class="input_box">
                      <FlatPickr v-model="dateValue" :config="{
                        minDate: 'today', altFormat: 'F j, Y', altInputClass: 'FlatPickr',
                      }">
                      </FlatPickr>
                      <span>{{ InternationalDate(dateValue) }}</span>
                    </div>
                  </div>
                  <div class="each_input_box">
                    <div class="name">
                      <span class="iconfont icon-shijian3" style="margin-right:5px"></span><span
                        style="font-weight: 400;">Pickup Time</span>
                    </div>
                    <div class="input_box">
                      <!-- <FlatPickr v-model="timeValue" :config="{
                        enableTime: true,
                        noCalendar: true,
                        altInput: true,
                        dateFormat: 'H:i',
                        altInputClass: 'FlatPickr',
                      }">
                      </FlatPickr> -->
                      <el-time-picker @change="changetime" class="FlatPickr" v-model="timeValue" :clearable="false"
                        value-format="HH:mm">
                      </el-time-picker>
                      <span>{{ timeValue }}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="pattern != 'Bythehour'">
                  <div class="return_btn" v-if="!return_show" @click="return_show_way">
                    ADD RETURN
                  </div>
                  <div class="time_box" v-else>
                    <div class="each_input_box">
                      <div class="name">
                        <span class="iconfont icon-riqi" style="margin-right:5px"></span><span
                          style="font-weight: 400;">Pickup Time</span>
                      </div>
                      <div class="input_box">
                        <FlatPickr v-model="returnDateValue" :config="{
                          minDate: convertDateToChinese(dateValue),
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                          altInputClass: 'FlatPickr',
                        }">
                        </FlatPickr>

                        <span>{{ InternationalDate(returnDateValue) }}</span>
                      </div>
                    </div>
                    <div class="each_input_box">
                      <div class="name">
                        <span class="iconfont icon-shijian3" style="margin-right:5px"></span><span
                          style="font-weight: 400;">Pickup Date</span>
                      </div>
                      <div class="input_box">
                        <el-time-picker @change="changetime" class="FlatPickr" v-model="returnTimeValue"
                          :clearable="false" value-format="HH:mm">
                        </el-time-picker>
                        <span>{{ returnTimeValue }}</span>
                      </div>
                      <span @click="return_show_way" style="
                          position: absolute;
                          top: -5px;
                          right: -5px;
                          color: rgb(50, 50, 50);
                        " class="iconfont icon-guanbixiao"></span>
                    </div>
                  </div>
                </div>
                <div v-if="pattern == 'Bythehour'" class="each_input_box" style="cursor: pointer"
                  @click.stop="Duration_show_way">
                  <div class="name" style="color: #8e8e8e">
                    <span class="iconfont icon-shijian"></span> <span style="font-weight: 400;">Duration</span>
                  </div>
                  <div class="input_box" style="line-height: 18px">
                    <span>{{ DurationNum }} hours</span>
                  </div>
                  <div class="people_number_view" style="
                      height: 400px;
                      overflow: auto;
                      border: 1px solid #252525;
                      padding: 0px 5px;
                    " @click.stop v-if="DurationShow">
                    <div class="hour" @click="DurationNum_way(item)" v-if="item != 1" v-for="(item, index) in 24"
                      :key="index">
                      {{ item }} hour
                    </div>
                  </div>
                </div>
                <div class="each_input_box" style="cursor: pointer" @click.stop="Passengers_show_way">
                  <div class="name" style="color: #8e8e8e">
                    <span class="iconfont icon-tuandui"></span> <span style="font-weight: 400;">Passengers</span>
                  </div>
                  <div class="input_box" style="line-height: 18px">
                    <span>{{ Passengers }}</span>
                  </div>
                  <div class="people_number_view" @click.stop v-if="PassengersShow">
                    <div class="category_box">
                      <div class="left_text">
                        <p>Adults</p>
                        <span>+12 years</span>
                      </div>
                      <div class="right_operation">
                        <div class="btn" @click.stop="AdultsNumCopy--" :class="{ btn4d4d4d: AdultsNumCopy == 1 }">
                          -
                        </div>
                        <div class="input">{{ AdultsNumCopy }}</div>
                        <div class="btn" @click.stop="AdultsNumCopy++">+</div>
                      </div>
                    </div>
                    <div class="category_box">
                      <div class="left_text">
                        <p>Children</p>
                        <span>2-12 years</span>
                      </div>
                      <div class="right_operation">
                        <div class="btn" @click.stop="ChildrenNumCopy--" :class="{ btn4d4d4d: ChildrenNumCopy == 0 }">
                          -
                        </div>
                        <div class="input">{{ ChildrenNumCopy }}</div>
                        <div class="btn" @click.stop="ChildrenNumCopy++">+</div>
                      </div>
                    </div>
                    <div class="category_box">
                      <div class="left_text">
                        <p>Infants</p>
                        <span>0-2 years</span>
                      </div>
                      <div class="right_operation">
                        <div class="btn" @click.stop="InfantsNumCopy--" :class="{ btn4d4d4d: InfantsNumCopy == 0 }">
                          -
                        </div>
                        <div class="input">{{ InfantsNumCopy }}</div>
                        <div class="btn" @click.stop="InfantsNumCopy++">+</div>
                      </div>
                    </div>
                    <div class="CONFIRM" @click.stop="Passengers_way">
                      CONFIRM
                    </div>
                  </div>
                </div>
                <div class="serac_btn" @click="path">
                  <span v-if="!searchBtnBool" style="margin-right: 5px" class="iconfont icon-sousuo"></span>
                  <span v-else style="margin-right: 5px" class="el-icon-loading"></span>
                  search
                </div>
              </div>

              <div class="bottom_view">
                <!-- <span>EXCELLENT</span> -->
                <div>
                  <div class="img_box" style="display: inline-block;">
                    <img
                      src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg"
                      alt="" />
                  </div>
                  <div style="display: inline-block; vertical-align: 4px; margin:0 5px">
                    <el-rate :value="5" disabled :colors="['#34e0a1', '#34e0a1', '#34e0a1']"></el-rate>
                  </div>
                </div>

                <span style="display: block;width: 100%;">Click To View Reviews</span>
              </div>
              <div class="bottom_view_zhegai" @click="pathevaluate"></div>
            </div>
          </div>
          <div class="explain_view">
            <p>
              Unlimited Waiting & Free Changing Service
              <img src="@/assets/logo/29c44193949b430125881afa71b38452.png" alt="" />
            </p>
            <span>
              You will never be left stranded at the airport, regardless of flight changes, long waiting at immigration
              or unexpected situation.
              <br>
              <i style="font-style: normal;;font-weight: 700;">
                Now you can get it at $19.99.
              </i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="operation_mode_view">
      <el-row class="plate">
        <el-col :md="{ span: 7, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="img_view ">
            <img src="@/assets/images/shouye1.png" alt="" />
          </div>
          <div class="title_view">Airport Transfers</div>
          <div class="illustrate">
            Book Competitively-Priced, Stress-Free Airport Transportation.
          </div>
          <div class="more_btn">Learn More</div>
        </el-col>
        <el-col :md="{ span: 7, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="img_view">
            <img src="@/assets/images/shouye2.png" alt="" />
          </div>
          <div class="title_view">Hourly Chauffeur</div>
          <div class="illustrate">
            Hire Hourly Chauffeur for Business or Leisure.
          </div>
          <div class="more_btn">Learn More</div>
        </el-col>
        <el-col :md="{ span: 7, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="img_view">
            <img src="@/assets/images/shouye.png" alt="" />
          </div>
          <div class="title_view">City Rides</div>
          <div class="illustrate">
            Travel Around the City with Convenience, Anytime, Anywhere.
          </div>
          <div class="more_btn">Learn More</div>
        </el-col>
      </el-row>
    </div>
    <div class="line_box plate"></div>
    <div class="illustrate_view">
      <el-row class="plate">
        <!-- <el-col class="right_box1" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-22-48.png" alt="" />
        </el-col> -->
        <el-col class="left_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="title"> Simple Online Booking</div>
          <div class="tips">
            Hassle-Free Booking - Quick, Simple, Ready When You Are. Your Ride, Your Terms.
          </div>
        </el-col>
        <el-col class="left_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="title">Transparent Pricing</div>
          <div class="tips">
            Seamless Booking - Instant Confirmation, Transparent Pricing, No Hidden Fees. Your Quoted Price is All You
            Pay for a Worry-Free Travel Experience.
          </div>
        </el-col>
        <el-col class="left_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="title">Complimentary Waiting Time</div>
          <div class="tips">
            Complimentary 1.5-Hour Waiting and Flight Tracking. Also Provide Optional Unlimited Waiting Service for a
            Stress-Free Journey.
          </div>
        </el-col>
        <el-col class="left_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="title">Professional Drivers</div>
          <div class="tips">
            Punctual Journeys Guaranteed - Our Seasoned Drivers Leverage Local Expertise and Traffic Insights. Travel
            with Confidence in the Hands of Licensed, Insured Professional Chauffeurs.
          </div>
        </el-col>
        <!-- <el-col class="right_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-22-48.png" alt="" />
        </el-col> -->
      </el-row>
      <el-row class="plate">
        <!-- <el-col class="right_box1" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-24-56.png" alt="" />
        </el-col> -->
        <!-- <el-col class="left_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="title">Transparent Pricing</div>
          <div class="tips">
            Seamless Booking - Instant Confirmation, Transparent Pricing, No Hidden Fees. Your Quoted Price is All You
            Pay for a Worry-Free Travel Experience.
          </div>
        </el-col> -->
        <!-- <el-col class="right_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-24-56.png" alt="" />
        </el-col> -->
      </el-row>
      <el-row class="plate">
        <!-- <el-col class="right_box1" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-26-31.png" alt="" />
        </el-col> -->
        <!-- <el-col class="left_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="title">Complimentary Waiting Time</div>
          <div class="tips">
            Seamless Travel Experience - Our Meet-and-Greet Service Includes Complimentary 1-Hour Waiting and Efficient
            Flight Tracking for a Stress-Free Journey. Enjoy the Convenience at No Additional Cost.
          </div>
        </el-col> -->
        <!-- <el-col class="right_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-26-31.png" alt="" />
        </el-col> -->
      </el-row>
      <el-row class="plate">
        <!-- <el-col class="right_box1" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-27-09.png" alt="" />
        </el-col> -->
        <!-- <el-col class="left_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <div class="title">Professional Drivers</div>
          <div class="tips">
            Punctual Journeys Guaranteed - Our Seasoned Drivers Leverage Local Expertise and Traffic Insights. Travel
            with Confidence in the Hands of Licensed, Insured Professional Chauffeurs.
          </div>
        </el-col> -->
        <!-- <el-col class="right_box" :md="{ span: 11, offset: 1 }" :sm="{ span: 23, offset: 1 }">
          <img src="@/assets/images/Snipaste_2024-07-12_14-27-09.png" alt="" />
        </el-col> -->
      </el-row>
    </div>
    <div class="line_box plate"></div>
    <div class="vehicle_model_view plate">
      <div class="mian_title">Wide Selection and Safe Arrival</div>
      <!-- <div class="sub_title">Licensed Vehicles with Professional Drivers</div> -->
      <div class="card_view">
        <el-scrollbar style="height: 100%; width: 100%">
          <div class="draggable-element" style="user-select: none">
            <div class="card" v-for="item, index in cardCars" :key="index">
              <div class="img">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="name">{{ item.carType }}</div>
              <div class="tips">{{ item.fwComon }}</div>
            </div>
            <!-- <div class="card">
              <div class="img">
                <img
                  src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/572c8b94-838e-4f92-de97-c2a42fab1e00/w=187,dpr=1.5"
                  alt="" />
              </div>
              <div class="name">Standard Class</div>
              <div class="tips">Up to 3 Passengers</div>
            </div>
            <div class="card">
              <div class="img">
                <img
                  src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/34c16f51-a36a-425c-a888-c7d64e97b900/w=187,dpr=1.5"
                  alt="" />
              </div>
              <div class="name">First Class</div>
              <div class="tips">Up to 3 Passengers</div>
            </div>
            <div class="card">
              <div class="img">
                <img
                  src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/d56982a7-35dc-47ff-81d7-a80841319a00/w=187,dpr=1.5"
                  alt="" />
              </div>
              <div class="name">SUV</div>
              <div class="tips">Up to 6 Passengers</div>
            </div>
            <div class="card">
              <div class="img">
                <img
                  src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/d69a50d1-e56e-458b-3dfa-f8a7f30e0f00/w=187,dpr=1.5"
                  alt="" />
              </div>
              <div class="name">Van Standard</div>
              <div class="tips">Up to 7 Passengers</div>
            </div>
            <div class="card">
              <div class="img">
                <img
                  src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/0fb9305d-7262-41b3-9416-03877bd80b00/w=187,dpr=1.5"
                  alt="" />
              </div>
              <div class="name">Van First Class</div>
              <div class="tips">Up to 6 Passengers</div>
            </div>
            <div class="card">
              <div class="img">
                <img
                  src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/835b35f9-c772-474e-8e46-7d2b90d60a00/w=187,dpr=1.5"
                  alt="" />
              </div>
              <div class="name">Minibus</div>
              <div class="tips">Up to 16 Passengers</div>
            </div> -->
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="how_does plate">
      <div class="title">How it Works</div>
      <div class="bottom_view">
        <div class="each">
          <div class="steps">
            <div class="the_first_step">1</div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div class="sub_title">Enter Route, Select Car</div>
            <div class="tisp">
              Enter Details, Choose Vehicle
            </div>
          </div>
        </div>
        <div class="each">
          <div class="steps">
            <div class="the_first_step">2</div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div class="sub_title">Complete Booking</div>
            <div class="tisp">
              Complete Booking Details - Enter Lead Passenger Info, Add Extras as Desired. Proceed to Payment and
              Receive Your Booking Voucher
            </div>
          </div>
        </div>
        <div class="each">
          <div class="steps">
            <div class="the_first_step">3</div>
            <div class="line"></div>
            <div class="complete">
              <i class="el-icon-check"></i>
            </div>
          </div>
          <div class="text">
            <div class="sub_title">Meet Your Driver</div>
            <div class="tisp">
              Driver Details Provided, Waiting with Name Sign (Optional)
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="area_view plate">
      <div class="title">
        Book Private Transfer at New York Airports
      </div>
      <div class="each_view">
        <div class="each">
          <div class="img">
            <img
              src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2020/05/milanoh.jpg/w=391,h=90,fit=cover,dpr=1.5"
              alt="" />

          </div>
          <div class="text_view">
            <p>New York</p>
            <span> John F. Kennedy International Airport (JFK)
            </span>
          </div>
        </div>
        <div class="each">
          <div class="img">
            <img
              src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2024/01/porto.jpg/w=391,h=90,fit=cover,dpr=1.5"
              alt="" />

          </div>
          <div class="text_view">
            <p>New York
            </p>
            <span>LaGuardia Airport (LGA) </span>
          </div>
        </div>
        <div class="each">
          <div class="img">
            <img
              src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2023/08/home-2.jpg/w=391,h=90,fit=cover,dpr=1.5"
              alt="" />

          </div>
          <div class="text_view">
            <p>New York </p>
            <span> Newark Liberty International Airport (EWR) </span>
          </div>
        </div>

      </div>
    </div> -->
    <div class="line_box plate"></div>
    <div class="Reviews_view plate">
      <div class="title">Reviews</div>
      <div class="sub_title">
        <span>Our customers say</span>
        <span>
          <strong>4.8</strong>
          out of 5 based on
          <strong>12,066 reviews</strong>
        </span>
      </div>
      <div class="swiper_view">

        <div class="swiper" style="overflow: hidden;" id="swiper-container">
          <div class="swiper-wrapper ">
            <div v-for="item, index in listAppRaiseList" :key="index" class="swiper-slide each" @click="pathevaluate">
              <div class="rate">
                <el-rate :value="item.score" disabled :colors="['#34e0a1', '#34e0a1', '#34e0a1']"></el-rate>
              </div>
              <!-- <h3>
                {{ item.createTime }}
              </h3> -->
              <p>
                {{ item.content }}
              </p>
              <span>
                <i>
                  {{ item.name }},
                </i>
                {{ item.timeStr }}
              </span>
            </div>
          </div>
          <div class="next" id="next">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="prev" id="prev">
            <i class="el-icon-arrow-left"></i>
          </div>
        </div>

      </div>
      <div class="bottom_title">
        <span>Rated <strong>4.8</strong> / 5 based on
          <strong class="bold-underline">12,066 reviews</strong> Showing our 4 &
          5 star reviews</span>
      </div>
    </div>
    <!-- <div class="line_box plate"></div> -->
    <!-- <div class="download_view plate">
      <div class="left">
        <div class="name">Sit Back and Relax!</div>
        <p>Download App</p>
        <span>Book, Modify, Cancel Rides.</span>
        <div class="btn">
          <span>
            <img src="https://www.transfeero.com/mainjs/apple_store.svg" alt="" />
          </span>

        </div>
      </div>
      <div class="right">
        <img src="https://imagedelivery.net/IwZOeeGEmDj8EVSTRphTwA/www.transfeero.com/2022/12/wowmoxk.png/dpr=1.5,w=424"
          alt="" />
      </div>
    </div> -->
    <div class="bottom_view plate">
      <div class="img_box">
        <div class="img">
          <img src="https://booking.transfeero.com/mainjs/svg/foot/vbv.svg" alt="" />
        </div>
        <div class="img">
          <img src="https://booking.transfeero.com/mainjs/svg/foot/msc.svg" alt="" />
        </div>
        <div class="img">
          <img src="https://booking.transfeero.com/mainjs/svg/foot/safekey2.svg" alt="" />
        </div>
      </div>
      <div class="img_box" style="margin: 30px 0">
        <div class="img" style="width: 150px">
          <img src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg "
            alt="" />
        </div>
      </div>
      <div class=" TRANSFEERO">
        2024 © KE Transfers ™ | All right reserved | 40-28 College Point Blvd, Queens, NY 11354 | +1 973 826 7620 |
      </div>
    </div>

    <el-dialog custom-class="payLikeView" title="Order Reminder" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false" :visible.sync="dialogVisible">
      <div class="daizhifu">
        <div class="title">Payment successful!</div>
        <div class="tips">
          Order payment completed, check after logging in
        </div>
        <div class="btnView">
          <div class="btn" @click="goPayWay">
            <span>Payment successful</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import navView from "@/components/navView.vue";
import FlatPickr from "vue-flatpickr-component";
import { mapState } from "vuex";
import "flatpickr/dist/flatpickr.css";
import "@/utils/swiper-3.4.2.min.css";
import "@/utils/swiper-3.4.2.min.js";
import { getSearchCarList, getOrderById, listCars, listAppRaise } from "@/utils/api";
export default {
  name: "HomeView",
  components: {
    navView,
    FlatPickr,
  },
  computed: {
    ...mapState({
      navValue: (state) => state.navValue,
    }),
  },
  data() {
    return {
      dialogVisible: false,
      // 当前模式
      pattern: "Ride",
      // 是否往返
      return_show: false,
      // 去 日期
      dateValue: "Please select",
      // 去 时间
      timeValue: "Please select",
      // 返 日期
      returnDateValue: "Please select",
      // 返 时间
      returnTimeValue: "Please select",
      // 添加人数弹框是否显示
      PassengersShow: false,
      // Adults人数
      AdultsNum: 1,
      AdultsNumCopy: 1,
      // Children人数
      ChildrenNum: 0,
      ChildrenNumCopy: 0,
      // Infants人数
      InfantsNum: 0,
      InfantsNumCopy: 0,
      // 总人数
      Passengers: 1,
      // 多少个小时
      DurationNum: 2,
      // 选择时间弹框是否显示
      DurationShow: false,
      // 选择地址的加载状态from
      selection_from_loading: true,
      // 选择地址的地点
      fromValue: "",
      // 跳转页面携带的参数from
      fromAddObj: {},
      // 选择地址的加载状态to
      selection_to_loading: true,
      // 选择地址的地点
      toValue: "",
      // 跳转页面携带的参数TO
      toAddObj: {},
      token: "",
      request: {
        input: "",
        includedPrimaryTypes: [
          // "hotel",
          // "resort_hotel",
          // "airport",
          // "train_station",
          // "street_address",
        ],
      },
      // 是否已经开始搜索
      searchBtnBool: false,
      fromValueList: [],
      fromsuggestions: [],
      toValueList: [],
      tosuggestions: [],
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      // shouyebeijing: '@/assets/images/shouye.png'
      shoyeindex: 0,
      //车型
      cardCars: [],
      mySwiper: '',
      listAppRaiseList: []
    };
  },
  watch: {
    dateValue: {
      handler(news) {
        if (!news) {
          this.dateValue = "Please select";
        }
      },
    },
    timeValue: {
      handler(news) {
        if (!news) {
          this.timeValue = "Please select";
        }
      },
    },
    returnDateValue: {
      handler(news) {
        if (!news) {
          this.returnDateValue = "Please select";
        }
      },
    },
    returnTimeValue: {
      handler(news) {
        if (!news) {
          this.returnTimeValue = "Please select";
        }
      },
    },
    navValue: {
      handler(newVal) {
        console.log(newVal);

        if (newVal == 2) {
          this.pattern_way("Bythehour");
        } else if (newVal == 1) {
          this.request.includedPrimaryTypes = [];
          this.pattern_way("Ride");
        } else if (newVal == 0) {
          this.pattern_way("Ride");
          this.request.includedPrimaryTypes = [
            // "hotel",
            // "resort_hotel",
            // "airport",
            // "locality",
          ];
        } else {
          this.pattern_way("Ride");
        }
      },
      deep: true,
    },
    pattern: {
      handler(newVal) {
        if (newVal == "Bythehour") {
          this.request.includedPrimaryTypes = [];
        } else {
          this.request.includedPrimaryTypes = [
            // "hotel",
            // "resort_hotel",
            // "airport",
            // "train_station",
            // "street_number",
          ];
        }
      },
    },
  },
  mounted() {
    console.log(this.$route.query);

    listCars().then((res) => {
      this.cardCars = res.result
    })
    listAppRaise().then((res) => {
      this.listAppRaiseList = res.result
    })
    this.$nextTick(async () => {
      this.searchBtnBool = true
      var currentDate = new Date();
      var hours = currentDate.getHours() < 10 ? '0' + currentDate.getHours() : currentDate.getHours();
      var minutes = currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
      this.timeValue = hours + ":" + minutes;
      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      var day = currentDate.getDate();
      this.dateValue = year + "-" + month + "-" + day;
      if (this.$route.query.modify) {
        var orderEntity = JSON.parse(sessionStorage.getItem("orderEntity"));
        this.fromAddObj = JSON.parse(sessionStorage.getItem("fromAddObj"));
        this.toAddObj = JSON.parse(sessionStorage.getItem("toAddObj"));
        this.fromValue = orderEntity.startAddress
        this.toValue = orderEntity.endAddress
        this.$nextTick(() => {
          console.log(orderEntity.startTime, '------');
          console.log(convertDateToChinese(orderEntity.startDate), '-----');
        })

        this.timeValue = orderEntity.startTime
        this.dateValue = convertDateToChinese(orderEntity.startDate)
        function convertDateToChinese(dateString) {
          const date = new Date(dateString);

          // 获取年份、月份和日期
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // 月份从0开始，需要加1
          const day = date.getDate();

          // 拼接成中文日期格式
          const chineseDate = `${year}-${month}-${day}-`;

          return chineseDate;
        }
      }
      if (this.$route.query.oid) {
        getOrderById({ oid: this.$route.query.oid }).then((res) => {
          if (res.code == 0) {
            this.searchBtnBool = false
            if (res.result.status == 1) {
              if (sessionStorage.getItem("objForm")) {
                sessionStorage.removeItem("objForm");
              }
              this.dialogVisible = true
            } else {
              this.$notify.error({
                title: "error",
                message: 'Order unpaid',
              });
            }
          } else {
            this.$notify.error({
              title: "error",
              message: res.msg,
            });
          }
        });
      } else {
        this.searchBtnBool = false
      }
      this.swiperWay()

    });
  },
  methods: {
    convertDateToChinese(dateString) {
      const date = new Date(dateString);

      // 获取年份、月份和日期
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份从0开始，需要加1
      const day = date.getDate();

      // 拼接成中文日期格式
      const chineseDate = `${year}-${month}-${day}-`;

      return chineseDate;
    },
    swiperWay() {
      this.mySwiper = new Swiper('#swiper-container', {
        autoplay: 2000,//可选选项，自动滑动
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slidesPerView: 'auto',
        prevButton: '#prev',
        nextButton: '#next',
      })
    },
    close_input(text) {
      this[text] = ''
      this.home_way()
    },
    goPayWay() {
      this.dialogVisible = false
    },
    // 转换时间格式
    InternationalDate(dateStr) {
      const englishDatePattern = /^(January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4}$/;
      if (englishDatePattern.test(dateStr)) {
        return dateStr
      }
      if (dateStr != "Please select") {
        const date = new Date(dateStr);
        const options = {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: 'UTC'
        };
        let dataNew = date.toLocaleDateString("en-US", options);
        return dataNew;
      } else {
        return "Please select";
      }
    },

    // 点击页面
    home_way() {
      if (this.PassengersShow) {
        this.Passengers_show_way();
      }
      if (this.DurationShow) {
        this.Duration_show_way();
      }
      if (document.getElementById("fromValue")) {
        const fromValue = document.getElementById("fromValue");
        if (fromValue.style.display !== "none") {
          fromValue.style.display = "none";
        }
      }
      if (document.getElementById("toValue")) {
        const toValue = document.getElementById("toValue");
        if (toValue.style.display !== "none") {
          toValue.style.display = "none";
        }
      }
    },
    // 打开alert
    alert_show_way() {
      this.$refs.nav_ref.alert_show_way("这是一个提示");
    },
    // 关闭alert
    alert_close_way() {
      this.$refs.nav_ref.alert_close_way();
    },
    // 切换当前模式
    pattern_way(type) {
      this.pattern = type;
    },
    // 是否往返
    return_show_way() {
      this.returnDateValue = "Please select";
      this.returnTimeValue = "Please select";
      this.return_show = !this.return_show;
    },
    // 是否显示选择人数弹框
    Passengers_show_way() {
      this.PassengersShow = !this.PassengersShow;
      if (this.PassengersShow) {
        this.AdultsNumCopy = this.AdultsNum;
        this.ChildrenNumCopy = this.ChildrenNum;
        this.InfantsNumCopy = this.InfantsNum;
      } else {
        this.AdultsNum = this.AdultsNumCopy;
        this.ChildrenNum = this.ChildrenNumCopy;
        this.InfantsNum = this.InfantsNumCopy;
      }
    },
    // 确定选择人数调用
    Passengers_way() {
      this.Passengers_show_way();
      this.Passengers = this.AdultsNum + this.ChildrenNum + this.InfantsNum;
    },
    Duration_show_way() {
      this.DurationShow = !this.DurationShow;
      if (this.DurationShow) {
        document.querySelector("body").classList.add("no-scroll");
      } else {
        document.querySelector("body").classList.remove("no-scroll");
      }
    },
    // 选择时长
    DurationNum_way(hour) {
      this.DurationNum = hour;
    },
    // 页面跳转
    path() {
      if (this.searchBtnBool) {
        return;
      }
      if (!this.fromAddObj.id) {
        this.$notify.error({
          title: "error",
          message: "Please enter the selected departure address",
        });
        return;
      }
      if (!this.toAddObj.id) {
        this.$notify.error({
          title: "error",
          message: "Please enter the destination address for selectio",
        });
        return;
      }
      if (this.dateValue == "Please select") {
        this.$notify.error({
          title: "error",
          message: "Please enter the selected departure date",
        });
        return;
      }
      if (this.timeValue == "Please select") {
        this.$notify.error({
          title: "error",
          message: "Please enter the selected departure time",
        });
        return;
      }
      if (this.return_show) {
        if (this.returnDateValue == "Please select") {
          this.$notify.error({
            title: "error",
            message: "Please enter the selection return date",
          });
          return;
        }
        if (this.returnTimeValue == "Please select") {
          this.$notify.error({
            title: "error",
            message: "Please enter the selection return time",
          });
          return;
        }
      }



      var obj = {
        type: this.pattern == "Ride" ? 0 : 1,
        startAddress: this.fromAddObj.country + "," + this.fromValue,
        endAddress: this.toAddObj.country + "," + this.toValue,
        startDate: this.InternationalDate(this.dateValue),
        startTime: this.timeValue,
        ripeNum: Number(this.AdultsNum),
        childrenNum: Number(this.ChildrenNum),
        babyNum: this.InfantsNum,
        startPoint: this.fromAddObj.lat + ", " + this.fromAddObj.lng,
        endPoint: this.toAddObj.lat + ", " + this.toAddObj.lng,
        fcDate: this.returnDateValue !== 'Please select' ? this.returnDateValue : '',
        fcTime: this.returnTimeValue !== 'Please select' ? this.returnTimeValue : '',
      };
      if (this.return_show) {
        obj.fcDate = this.returnDateValue !== 'Please select' ? this.InternationalDate(this.returnDateValue) : '';
        obj.fcTime = this.returnTimeValue !== 'Please select' ? this.returnTimeValue : '';
      }
      console.log(obj);
      this.searchBtnBool = true;
      getSearchCarList(obj).then((res) => {
        if (res.code == 0) {
          sessionStorage.setItem(
            "orderEntity",
            JSON.stringify(res.orderEntity)
          );
          sessionStorage.setItem(
            "fromAddObj",
            JSON.stringify(this.fromAddObj)
          );
          sessionStorage.setItem(
            "toAddObj",
            JSON.stringify(this.toAddObj)
          );
          sessionStorage.setItem(
            "carCityList",
            JSON.stringify(res.carCityList)
          );
          this.$nextTick(() => {
            this.searchBtnBool = false;
            this.$router.push("/about");
          });
        } else {
          this.searchBtnBool = false;
          this.$notify.error({
            title: "error",
            message: res.msg,
          });
        }
      }).catch((err) => {
        console.log(err);
        this.searchBtnBool = false;
        this.$notify.error({
          title: "error",
          message: err.msg,
        });
      })
    },
    // 跳转评价
    pathevaluate() {
      window.open('https://www.tripadvisor.com/Attraction_Review-g60763-d26247498-Reviews-KE_Transfer-New_York_City_New_York.html', "_blank");
    },
    // from是否变成输入框
    show_txt_way() { },
    // 获取地址from
    async blur_way() {
      var _this = this
      if (this.fromValue == "") {
        this.selection_from_loading = false;
        const fromValue = document.getElementById("fromValue");
        fromValue.style.display = "none";
      } else {
        const fromValue = document.getElementById("fromValue");
        fromValue.style.display = "block";
        this.selection_from_loading = true;
        this.request.input = this.fromValue;
        // const resultsElement = document.getElementById("results");
        // resultsElement.innerHTML = "";
        this.fromValueList = []
        const { AutocompleteSessionToken, AutocompleteSuggestion } = await google.maps.importLibrary("places");
        this.token = new AutocompleteSessionToken();
        this.request.sessionToken = this.token;
        const { suggestions } =
          await AutocompleteSuggestion.fetchAutocompleteSuggestions(
            this.request
          );
        this.fromsuggestions = suggestions
        for (let suggestion of suggestions) {
          const placePrediction = suggestion.placePrediction;
          this.fromValueList.push(placePrediction.text.toString())
          // const listItem = document.createElement("li");
          // listItem.addEventListener("click", (event) => {
          //   event.stopPropagation();
          //   this.fromValue = event.target.innerText;
          //   fromValue.style.display = "none";
          //   const addObj = this.AddressDetails(this.fromValue, suggestions);
          //   addObj.then((res) => {
          //     this.fromAddObj = res;
          //   });
          // });

          // listItem.appendChild(
          //   document.createTextNode(placePrediction.text.toString())
          // );
          // resultsElement.appendChild(listItem);
          this.selection_from_loading = false;
        }
      }
    },
    // 获取地址to
    async to_blur_way() {
      if (this.toValue == "") {
        this.selection_to_loading = false;
        const toValue = document.getElementById("toValue");
        toValue.style.display = "none";
      } else {
        const toValue = document.getElementById("toValue");
        toValue.style.display = "block";
        this.selection_to_loading = true;
        this.request.input = this.toValue;
        const resultsElement = document.getElementById("resultss");
        resultsElement.innerHTML = "";
        const { Place, AutocompleteSessionToken, AutocompleteSuggestion } =
          await google.maps.importLibrary("places");
        this.token = new AutocompleteSessionToken();
        this.request.sessionToken = this.token;
        const { suggestions } =
          await AutocompleteSuggestion.fetchAutocompleteSuggestions(
            this.request
          );
        this.tosuggestions = suggestions
        for (let suggestion of suggestions) {
          const placePrediction = suggestion.placePrediction;
          this.toValueList.push(placePrediction.text.toString())
          // const listItem = document.createElement("li");
          // listItem.addEventListener("click", (event) => {
          //   event.stopPropagation();
          //   this.toValue = event.target.innerText;
          //   toValue.style.display = "none";
          //   const addObj = this.AddressDetails(this.toValue, suggestions);
          //   addObj.then((res) => {
          //     this.toAddObj = res;
          //   });
          // });
          // listItem.appendChild(
          //   document.createTextNode(placePrediction.text.toString())
          // );
          // resultsElement.appendChild(listItem);
          this.selection_to_loading = false;
        }
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.touchStartY = event.touches[0].clientY;
    },
    handleTouchEndfrom(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      this.touchEndY = event.changedTouches[0].clientY;
      const deltaX = this.touchEndX - this.touchStartX;
      const deltaY = this.touchEndY - this.touchStartY;
      if (Math.abs(deltaX) < 10 && Math.abs(deltaY) < 10) {
        this.clickfromValueway(event.target.innerText)
      }
    },
    clickfromValueway(text) {
      this.fromValue = text
      const fromValue = document.getElementById("fromValue");
      fromValue.style.display = "none";
      const addObj = this.AddressDetails(this.fromValue, this.fromsuggestions);
      addObj.then((res) => {
        this.fromAddObj = res;
      });
    },
    formisPCWay(event) {
      console.log(event);
      console.log(this.isPC());
      if (this.isPC()) { }
      this.clickfromValueway(event.target.innerText)
    },
    toisPCWay(event) {
      console.log(this.isPC());
      console.log(event);
      if (this.isPC()) {

        this.clicktoValueway(event.target.innerText)
      }
    },
    isPC() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /mobile|android|iphone|ipad|phone|tablet|touch/i.test(userAgent);
      return !isMobile;
    },
    handleTouchEndto(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      this.touchEndY = event.changedTouches[0].clientY;
      const deltaX = this.touchEndX - this.touchStartX;
      const deltaY = this.touchEndY - this.touchStartY;
      if (Math.abs(deltaX) < 10 && Math.abs(deltaY) < 10) {
        this.clicktoValueway(event.target.innerText)
      }
    },
    clicktoValueway(text) {
      this.toValue = text
      const toValue = document.getElementById("toValue");
      toValue.style.display = "none";
      const addObj = this.AddressDetails(this.toValue, this.tosuggestions);
      addObj.then((res) => {
        this.toAddObj = res;
      });
    },
    // 获取地址details
    async AddressDetails(text, arr) {
      var place;
      arr.forEach((item) => {
        if (item.placePrediction.text.toString() == text) {
          place = item.placePrediction.toPlace();
        }
      });
      await place.fetchFields({
        fields: [
          "displayName",
          "formattedAddress",
          "location",
          "addressComponents",
        ],
      });
      var id = place.id;
      var displayName = place.displayName;
      var formattedAddress = place.formattedAddress;
      var country;
      place.addressComponents.forEach((items) => {
        if (items.types[0] == "country") {
          country = items.longText;
        }
      });
      var lat = place.location.toString().split(",")[0];
      var lng = place.location.toString().split(",")[1];
      lat = lat.substring(1, lat.length);
      lng = lng.substring(0, lng.length - 1);
      return {
        displayName: displayName,
        formattedAddress: formattedAddress,
        lat: lat - 0,
        lng: lng - 0,
        id: id,
        country: country,
      };
    },
    changetime(e) {
      console.log(e, this.timeValue);
    },
    next() {
      this.$refs.swiperId.next()
    },
    prev() {
      this.$refs.swiperId.prev()
    },
    timeAgo(date) {
      const now = new Date();
      const secondsPast = Math.floor((now - date) / 1000);

      if (secondsPast < 60) {
        return `${secondsPast} 秒前`;
      }
      if (secondsPast < 3600) {
        return `${Math.floor(secondsPast / 60)} 分钟前`;
      }
      if (secondsPast < 86400) {
        return `${Math.floor(secondsPast / 3600)} 小时前`;
      }
      if (secondsPast < 172800) {
        return `昨天`;
      }
      if (secondsPast < 2592000) { // within 30 days
        return `${Math.floor(secondsPast / 86400)} 天前`;
      }
      if (secondsPast < 31536000) { // within a year
        return `${Math.floor(secondsPast / 2592000)} 个月前`;
      }
      return `${Math.floor(secondsPast / 31536000)} 年前`;
    }
  },
};
</script>
<style lang="scss">
.head_view {
  position: relative;

  .head_background {

    height: 625px;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: -1;
    width: 100vw;
  }

  .head_background1 {
    background-image: url("@/assets/images/shouye.png");
  }

  .head_background2 {
    background-image: url("@/assets/images/shouye1.png");
  }

  .head_background3 {
    background-image: url("@/assets/images/shouye2.png");
  }

  .head_background_two {
    background-image: url("@/assets/images/Snipaste_2024-06-26_16-27-23.png");
    height: 300px;
    background-size: 100% 100%;
    z-index: -1;
    width: 100vw;
  }

  .head_upper_strata {
    background: linear-gradient(182deg, #191821 0, rgba(37, 47, 48, 0.7) 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 101vw;
    height: 100%;

    .operation_view {
      position: absolute;
      top: 175px;
      width: 100%;
      display: flex;

      .left_view {
        flex: 1;
        font-size: 50px;
        color: white;
        font-weight: 700;
      }

      .query_view {
        position: relative;

        .choice_view {
          position: relative;
          margin-left: 20px;
          color: #212121;
          overflow: hidden;
          display: flex;
          z-index: 1;
          margin-bottom: -1px;

          .each {
            border-top-left-radius: 5px;
            padding: 6px 17px 5px;
            background-color: #e2e2e2;
            cursor: pointer;
          }

          .select_each {
            background-color: #fff;
            font-weight: 700;
          }

          .each:nth-child(2) {
            border-top-left-radius: 0px;
            border-top-right-radius: 5px;
          }
        }

        .condition_view {
          padding: 15px;
          background-color: #fff;
          border-radius: 10px;
          width: 440px;
          box-shadow: -1px 8px 35px 8px rgb(2 16 29);

          .each_input_box {
            position: relative;
            padding: 10px;
            background-color: #ececec;
            border-radius: 10px;
            margin-bottom: 10px;

            .selection_box {
              display: none;
              background-color: #fff;
              min-height: 100px;
              max-height: 300px;
              width: calc(100% - 30px);
              position: absolute;
              top: 100%;
              z-index: 99;
              border-radius: 10px;
              padding: 10px;
              box-shadow: -1px 14px 30px -6px rgb(2, 16, 29);

              li {
                list-style: none;
                cursor: pointer;
                line-height: 30px;
                font-size: 18px;
                border-bottom: 2px solid #cccccc50;
                padding: 10px 20px;
                transition: 0.3s;
                user-select: none;

              }

              // li:hover {
              //   background-color: #f9582110;
              // }
            }

            .name {
              font-weight: 700;
            }

            .input_box {
              display: flex;
              margin-top: 10px;
              line-height: 30px;


              input::placeholder {
                font-weight: 400;
                color: #ccc;
              }

              .close_input {
                position: absolute;
                right: 10px;
                top: 10px;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                font-size: 20px;
              }

              input,
              .input {
                flex: 1;
                // min-height: 30px;
                line-height: 30px;
                margin-left: 10px;
                border: none;
                outline: none;
                background-color: transparent;
                letter-spacing: 1px;
                font-size: 18px;
                font-weight: 700;
                white-space: break-word;
                word-break: break-all;
                cursor: text;
                /* 也可以去除焦点时的轮廓 */
              }

              .input {
                color: #21212150;
              }
            }

            .people_number_view {
              position: absolute;
              right: 0;
              margin-top: 10px;
              background-color: #fff;
              padding: 20px 10px;
              width: 276px;
              border-radius: 3px;
              -webkit-box-shadow: 1px 1px 6px -2px #9e9e9e;
              -moz-box-shadow: 1px 1px 6px -2px #9e9e9e;
              box-shadow: 1px 1px 6px -2px #9e9e9e;
              z-index: 9999;

              .hour {
                padding: 3px 10px;
                font-size: 16px;
                transition: 0.3s;
              }

              .hour:hover {
                background-color: #ffc107;
              }

              .category_box {
                display: flex;
                justify-content: space-between;
                padding: 0 20px;
                padding-bottom: 8px;
                margin-bottom: 10px;

                .left_text {
                  flex: 1;

                  p {
                    line-height: 1.5;
                  }

                  span {
                    color: grey;
                    line-height: 1.5;
                    font-size: 12px;
                  }
                }

                .right_operation {
                  display: flex;
                  line-height: 40px;

                  .btn {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    background-color: #252525;

                    text-align: center;
                    color: #fff;
                    user-select: none;
                  }

                  .btn4d4d4d {
                    background-color: #4d4d4d;
                    opacity: 0.5;
                    pointer-events: none;
                    cursor: not-allowed;
                  }

                  .input {
                    width: 40px;
                    text-align: center;
                    user-select: none;
                  }
                }
              }

              .CONFIRM {
                background-image: linear-gradient(to right,
                    #000 0,
                    #323232 51%,
                    #000 100%);
                padding: 10px 45px;
                text-align: center;
                text-transform: uppercase;
                transition: 0.5s;
                background-size: 200% auto;
                color: #fff;
                border-radius: 5px;
                font-weight: 700;
                user-select: none;
              }

              .CONFIRM:hover {
                background-position: right center;
                color: #fff;
                text-decoration: none;
              }
            }
          }

          .time_box {
            display: flex;

            .each_input_box {
              position: relative;
              flex: 1;
              cursor: pointer;

              .name {
                color: #8e8e8e;
              }

              .input_box {
                position: relative;
                line-height: 18px;
              }
            }

            .each_input_box:nth-child(1) {
              margin-right: 5px;
            }

            .each_input_box:nth-child(2) {
              margin-left: 5px;
            }
          }

          .return_btn {
            border-radius: 10px;
            padding: 17px;
            margin-bottom: 10px;
            cursor: pointer;
            background-color: rgb(255, 255, 255);
            color: rgb(63, 63, 63);
            border-color: rgb(221, 221, 221);
            border: 1px solid rgb(221, 221, 221);
            text-align: center;
            -webkit-user-select: none;
            /* Safari */
            -moz-user-select: none;
            /* Firefox */
            -ms-user-select: none;
            /* IE/Edge */
            user-select: none;
            /* 标准语法 */
          }

          .serac_btn {
            background-image: linear-gradient(to right,
                #000 0,
                #323232 51%,
                #000 100%);
            padding: 15px 45px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            border-radius: 5px;
            font-weight: 700;
            cursor: pointer;
            background-size: 200% auto;
            transition: 0.5s;
            font-size: 18px;
          }

          .serac_btn:hover {
            background-position: right center;
            color: #fff;
            text-decoration: none;
          }
        }

        .bottom_view {
          position: relative;
          width: 400px;
          background-color: #fff;
          border-radius: 5px;
          padding: 15px;
          line-height: 1.5;

          justify-content: center;
          font-weight: 700;
          margin: 0 20px;
          z-index: -1;

          .img_box {
            height: 17px;
            margin-left: 5px;
            margin-top: 4px;
          }
        }

        .bottom_view_zhegai {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          padding: 15px;
          width: 400px;
          height: 17px;
          cursor: pointer;
          background-color: transparent;
        }
      }
    }

    .explain_view {
      position: absolute;
      top: 650px;
      left: 0px;
      background-color: #232323;
      width: 25%;
      padding: 30px;
      color: #fff;
      border-radius: 12px;

      p {
        font-weight: 300;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 20px;

        img {
          width: 37px !important;
          height: 37px !important;
          vertical-align: -10px;
        }
      }

      span {
        line-height: 25px;
      }
    }
  }

  .imgView {
    width: calc(100vw - 30px);
  }
}

.operation_mode_view {
  padding-top: 50px;

  .img_view {
    max-width: 360px;
    height: 150px;
    /* background-color: deeppink;*/
    border-radius: 15px;
    overflow: hidden;
  }

  .title_view {
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 0px;
    margin-bottom: 4px;
    margin-top: 20px;
  }

  .illustrate {
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 25px;
  }

  .more_btn {
    text-decoration: underline;
    color: #212121;
    font-weight: 700;
    font-size: 16px;
  }
}

.line_box {
  position: relative;
  clear: left;
  border-bottom: 1px solid #e9e9e9;
  margin-top: 60px;
  margin-bottom: 70px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-color: #fff;
    background-image: url("@/assets/logo/WechatIMG129.jpg");
    background-size: 100% 100%;
  }
}

.illustrate_view {
  padding-bottom: 50px;

  .plate {
    margin-bottom: 30px;

    &:nth-child(even) {
      .right_box {
        display: none;
      }
    }

    &:nth-child(odd) {
      .right_box1 {
        display: none;
      }
    }
  }

  .left_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 205px;
    border-bottom: 7px solid #f9582150;

    .title {
      font-size: 35px;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .tips {
      font-size: 16px;
      line-height: 26px;
      color: #676767;
    }
  }

  .right_box,
  .right_box1 {
    height: 305px;
    border-bottom: 7px solid #ffefd3;
  }
}

.vehicle_model_view {
  padding-top: 30px;
  text-align: center;

  .mian_title {
    font-weight: 500;
    font-size: 28px;
  }

  .sub_title {
    font-size: 18px;
  }

  .card_view {
    padding: 20px 0;
    overflow-x: scroll;
    white-space: nowrap;

    .card {
      padding: 10px;
      text-align: center;
      width: 140px;
      display: inline-block;
      margin-bottom: 30px;

      .img {
        width: 140px;
        height: 93px;
      }

      .name {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 0;
      }

      .tips {
        display: block;
        font-size: 15px;
        color: #5a5a5a;
        margin-top: -4px;
      }
    }
  }
}

.how_does {
  margin-top: 65px;
  margin-bottom: 45px;
  background-color: #e5e5e5;
  padding: 30px 14px 60px 14px;
  border-radius: 20px;

  .title {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .bottom_view {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding: 0 100px;

    .each {
      position: relative;
      flex: 1;

      .text {
        text-align: left;
      }

      .steps {
        position: relative;
        display: flex;
        text-align: center;

        .complete {
          position: absolute;
          right: 0%;
          top: 0px;
          border-radius: 5px;
          background-color: #c1e6d2;
          width: 45px;
          height: 45px;
          text-align: center;
          line-height: 45px;
          color: #49bf80;
          font-weight: 700;
          font-size: 18px;
        }

        .the_first_step {
          min-width: 45px;
          color: #fff;
          border-radius: 5px;
          background-color: #f95821;
          width: 45px;
          height: 45px;
          font-size: 19px;
          font-weight: 700;
          line-height: 45px;
        }

        .line {
          position: relative;
          display: flex;
          width: 90%;

          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            width: 95%;
            height: 2px;
            background-color: #e8dfd0;
          }
        }
      }

      .sub_title {
        color: #4c4c4c;
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
        padding-right: 10px;
      }

      .tisp {
        padding-right: 10px;
        color: #696969;
        font-size: 14px;
        font-weight: 300;
        margin-top: 10px;
      }
    }
  }
}

.area_view {
  margin-top: 50px;
  margin-bottom: 100px;

  .title {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 40px;
  }

  .each_view {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .each {
      width: 33%;
      background-color: #fff;
      margin-bottom: 10px;
      box-shadow: 0px 6px 12px -3px rgba(0, 0, 0, 0.1);
      transition: 0.3s;
      cursor: pointer;
      border-radius: 7px;
      overflow: hidden;

      .img {
        position: relative;
        width: 100%;
        box-sizing: border-box;

        // background-color: skyblue;
        .tag {
          font-size: 12px;
          position: absolute;
          z-index: 999;
          padding: 1px 5px;
          margin: 10px 10px;
          background-color: #ffffff;
          border-radius: 2px;
          color: #646464;
          font-weight: 300;
          right: 0px;
          bottom: 0px;
        }
      }

      .text_view {
        padding: 10px;

        p {
          margin-bottom: 0.5rem;
          font-weight: 500;
          line-height: 1.2;
          font-size: 22px;
        }

        span {
          font-size: 15px;
          color: #777474;
          font-weight: 300;
        }
      }
    }

    .each:hover {
      background-color: #e8e8e8;
    }
  }
}

.Reviews_view {
  text-align: center;
  padding: 60px 0;

  .title {
    font-size: 38px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .sub_title {
    display: flex;
    justify-content: center;

    span {
      color: #191919;
      font-size: 12px;
    }

    i {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      margin: 0 10px;
    }

    .img_box {
      // width: 190px;
      height: 17px;
      margin-left: 5px;
    }
  }

  .swiper_view {
    position: relative;
    height: 200px;
    margin: 20px 0;

    .prev {
      position: absolute;
      top: 50%;
      left: -25px;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #ccc;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      top: 50%;
      right: -25px;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #ccc;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }

    .swiper {
      width: 90%;
      height: 100%;
      margin: 0 auto;

      // .itemView {
      // display: flex;
      // overflow: hidden;
      // width: 100%;
      // height: 100%;
      // justify-content: center;
      // flex-wrap: wrap;

      .each {
        width: 250px !important;
        height: 100%;
        margin: 0 10px;
        text-align: left;
        background-color: #f8f9fa;
        border-radius: 2px;
        padding: 15px;
        cursor: pointer;

        .rate {
          padding-bottom: 5px;
        }

        h3 {
          font-weight: 700;
          font-size: 14px;
          color: #1f2936;
          padding-bottom: 5px;
        }

        p {
          font-size: 14px;
          color: #191919;
          margin-bottom: 10px;
        }

        span {


          i {
            font-weight: 700;
            font-style: normal;
            display: inline-block;
          }

          font-size: 14px;
          color: rgba(0, 0, 0, .6);
        }
      }

      // }
    }
  }

  .bottom_title {
    span {
      color: #191919;
      font-size: 12px;
    }
  }
}

.download_view {
  display: flex;
  padding: 20px 0;

  .left {
    flex: 1;
    padding-top: 30px;

    .name {
      font-weight: 300;
      font-size: 45px;
      padding-bottom: 15px;
    }

    p {
      font-weight: 700;
      font-size: 30px;
    }

    span {
      font-weight: 400;
      font-size: 20px;
    }

    .btn {
      margin-top: 100px;

      span {
        display: inline-block;
        width: 145px;
        // height: 45px;
        margin: 0 10px;
      }
    }
  }

  .right {
    flex: 1;
  }
}

.bottom_view {
  text-align: center;

  .img_box {
    display: flex;
    justify-content: center;

    .img {
      width: 75px;
      margin: 0px 8px;
      filter: grayscale(100%);
    }
  }

  .TRANSFEERO {
    font-size: 13px;
    color: #212121;
    margin-top: 30px;
    padding: 50px;
  }
}

@media screen and (max-width: 1400px) {
  .head_view {
    .head_upper_strata {
      .operation_view {
        .left_view {
          font-size: 40px;
          margin: 30px 0;

          span {
            font-size: 24px !important;
            line-height: 30px !important;
          }
        }
      }

      .explain_view {
        width: 25%;

        p {
          font-size: 25px;

          img {
            width: 40px !important;
            vertical-align: -8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .head_view {
    .head_upper_strata {
      .operation_view {
        .left_view {
          font-size: 36px;
          margin: 30px 0;

          span {
            font-size: 22px !important;
            line-height: 25px !important;
          }
        }

        .query_view {
          .condition_view {
            width: 400px;
          }

          .bottom_view,
          .bottom_view_zhegai {
            width: calc(100% - 70px);
          }
        }
      }

      .explain_view {
        width: 25%;

        p {
          font-size: 20px;

          img {
            width: 37px !important;
            vertical-align: -8px;
          }
        }
      }
    }
  }
}

.payLikeView {
  width: 500px;
  height: 300px;

  .daizhifu {
    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }

    .tips {
      font-size: 14px;
      text-align: center;
      margin: 30px 0;
    }

    .btnView {
      margin: 30px 0;
      text-align: center;

      .btn {
        // float: right;
        // display: inline-block;
        // width: 188px;
        padding: 10px 34px;
        background-image: linear-gradient(to right,
            #000000 0%,
            #404040 51%,
            #000000 100%);
        font-size: 1rem;
        line-height: 1.5;
        user-select: none;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        margin-top: 10px;
        cursor: pointer;
        transition: 0.5s;
        background-size: 200% auto;
      }

      .btn:hover {
        background-position: right center;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .head_view {
    .head_upper_strata {
      .plate {
        width: 100% !important;
      }

      .operation_view {
        position: relative;
        top: 0;
        display: block;

        .left_view {
          width: 85%;
          font-size: 18px;
          margin: 30px auto;

          span {
            font-size: 14px !important;
            line-height: 18px !important;
          }
        }

        .query_view {
          .condition_view {
            width: calc(100% - 30px);
          }

          .bottom_view_zhegai {
            width: calc(100% - 150px);
          }

          .bottom_view {
            display: block;
            width: calc(100% - 150px);
            margin: 0 auto;
            text-align: center;

            span {
              // display: block;
            }

            .img_box {
              margin: 0 auto;
            }
          }
        }
      }

      .explain_view {
        position: relative;
        width: calc(100% - 60px);
        top: 0px;
        margin-top: 30px;

        p {
          font-weight: 300;
          font-size: 30px;
          line-height: 38px;
          margin-bottom: 10px;

          img {
            width: 30px !important;
            height: 30px !important;
            vertical-align: -4px;
          }
        }

        span {
          line-height: 20px;
        }
      }
    }
  }

  .operation_mode_view {
    .img_view {
      max-width: 450px;
      height: 180px;
    }

    .more_btn {
      margin-bottom: 30px;
    }
  }

  .line_box {
    &::before {
      width: 40px;
      height: 40px;
    }
  }

  .illustrate_view {
    margin-top: 50px;

    .plate {
      .right_box1 {
        display: none;
      }

      .right_box {
        display: inline-block !important;
        height: 240px;
      }

      .left_box {
        height: auto;
        padding-bottom: 30px;
        margin-bottom: 50px;

        .title {
          font-size: 30px;
        }
      }
    }
  }

  .how_does {
    margin: 50px auto !important;

    .bottom_view {
      flex-direction: column;
      padding: 0 20px;

      .each {
        .sub_title {
          margin-top: 0px;
          line-height: 45px;
        }

        .text {
          margin-left: 20px;
        }

        .steps {
          display: block;

          .complete {
            top: 90%;
          }

          .line {
            width: 45px;
            height: 100px;

            &::before {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              content: "";
              width: 2px;
              height: 100px;
              background-color: #e8dfd0;
            }
          }
        }

        display: flex;
      }
    }
  }

  .Reviews_view {
    .sub_title {
      display: block;
    }
  }

  .area_view {
    .each_view {
      .each {
        width: 49%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .plate {
    width: 85%;
    margin: 0 auto;
  }

  .area_view {
    margin-top: 50px;
    margin-bottom: 50px;

    .each_view {
      .each {
        width: 100%;
      }
    }
  }

  .download_view {
    .left {
      font-size: 36px;

      .name {
        font-size: 38px;
      }

      .btn {
        margin-top: 37px;
      }
    }

    .right {
      display: none;
    }
  }
}

@media screen and (max-width: 400px) {
  .head_view {
    .head_upper_strata {
      .explain_view {
        p {
          font-size: 15px;

          img {
            width: 13px !important;
            height: 19px !important;
          }
        }
      }
    }
  }
}
</style>
<style>
.FlatPickr,
.flatpickr-input {
  position: absolute !important;
  top: -5px;
  left: -5px;
  width: 100% !important;
  height: 30px;
  line-height: 30px;
  border: none;
  outline: none;
  background-color: transparent;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 700;
  color: transparent;
  cursor: pointer;
  opacity: 0;
}

.el-carousel__container {
  height: 100% !important;
}

.el-carousel__arrow {
  display: none !important;
}

.el-rate__icon {
  margin-right: 0 !important;
}

.el-loading-spinner i {
  color: #f95821 !important;
}

.el-loading-spinner .el-loading-text {
  color: #f95821 !important;
}

.el-loading-mask {
  border-radius: 10px !important;
}

.el-time-panel__btn.confirm {
  color: #f95821 !important;
}

.flatpickr-day.selected {
  background: #f95821 !important;
  border-color: #f95821 !important;
}

@media screen and (max-width: 750px) {
  .el-dialog {
    width: 95% !important;
  }
}
</style>
